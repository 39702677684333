export const AUDIENCE = {
  ADULT: 'ADULT',
  JUVENILE: 'JUVENILE',
  TEEN: 'TEEN',
  UNKNOWN: 'UNKNOWN'
};

export const CONTENT_TYPE = {
  FICTION: 'FICTION',
  GOVERNMENT_DOCUMENTS: 'GOVERNMENT_DOCUMENTS',
  NONFICTION: 'NONFICTION',
  UNDETERMINED: 'UNDETERMINED'
};

// TODO: build-out the enum of supported bib format IDs
export const FORMAT_ID = {
  AUDIOBOOK: 'AB',
  BOARD_BOOK: 'BOARD_BK',
  BOOK: 'BK',
  BOOK_PLUS_AUDIO: 'BOOK_PAUDIO',
  DVD: 'DVD',
  EBOOK: 'EBOOK',
  EASY_READER: 'EASY_READER',
  GRAPHIC_NOVEL: 'GRAPHIC_NOVEL',
  KIT: 'KIT',
  LARGE_PRINT: 'LPRINT',
  LONG_PLAY: 'LP',
  MICROFORM: 'MF',
  MUSIC_CD: 'MUSIC_CD',
  PAPERBACK: 'PAPERBACK',
  PRINTED_MUSIC: 'MN',
  STREAMING_MUSIC: 'MUSIC_ONLINE',
  UNKNOWN: 'UK',
  WEBSITE: 'WEBSITE'
};

export const FULFILLMENT_PROVIDER = {
  HOOPLA: 'HooplaAPI',
  ILS: 'ILS',
  OVER_DRIVE: 'OverDriveAPI'
};

export const TITLE_TYPE = {
  MANIFESTATION: 'MANIFESTATION',
  VARIANT: 'VARIANT'
};
