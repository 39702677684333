import cn from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import { TertiarySolidLink } from '@bibliocommons/base-links';
import { childrenShape, paginationShape } from '@bibliocommons/bc-prop-types';
import Heading from '@bibliocommons/heading';
import Pagination from '@bibliocommons/pagination';

import { BibJacket } from 'app/components/bibs/shared/BibJacket';
import AuthorLink from 'app/components/shared/AuthorLink';
import BackToLink from 'app/components/shared/BackToLink';
import Tabs from 'app/components/shared/Tabs';
import displayBibShape from 'app/shapes/displayBib';

import './BrowseTitlesPage.scss';

/*
Note: rendering the {children}, even if null, is required to trigger the fetching of route data
in the corresponding connected page route.
*/

const BrowseTitlesPage = ({
  children,
  displayBibs,
  isFetching,
  navItems,
  pageTitle,
  pagination,
  renderActiveFilters,
  renderFacets,
  renderMobileMenuOverlay,
  tabLinks
}) => {
  const renderPanelBody = () => {
    return (
      <section>
        <div className="row">
          <div className="col-lg-3">
            <div className="facets">{renderFacets()}</div>
          </div>
          <div className="col-lg-9">
            <section className="active-filters">{renderActiveFilters()}</section>
            {children}
            <ul className="row bib-list" data-key="display-bibs">
              {displayBibs.map(bib => {
                const { authors, formatId, imageUrl, metadataId, title } = bib;

                return (
                  <li className="col-12 col-xs-6 col-md-4 bib-list-item" key={metadataId}>
                    <div className="bib-jacket-bg">
                      <div className="bib-jacket-wrapper">
                        <BibJacket
                          cover={Immutable.Map({ large: imageUrl })}
                          format={formatId}
                          metadataId={metadataId}
                          renderAsLink
                        />
                      </div>
                    </div>
                    <Heading className="bib-heading" size="medium" tagName="h2" variant>
                      <a href={`/v2/record/${metadataId}`}>{title}</a>
                    </Heading>
                    <AuthorLink authors={authors} />
                  </li>
                );
              })}
            </ul>

            <footer className="row">
              <div className="col-md-12">
                <Pagination isFetching={isFetching} pagination={pagination} showFirstPage showLastPage />
              </div>
            </footer>
          </div>
        </div>
      </section>
    );
  };

  const tabOptions = tabLinks.map(tabLink => {
    const { id, text: label, to: url } = tabLink;

    return {
      id,
      label,
      renderPanelBody,
      url
    };
  });

  return (
    <div className="cp-browse-titles-page container">
      <header>
        <div className="row nav-row">
          <div className="col-6 col-md-3">
            <BackToLink href="/" pageId="browse" />
          </div>
          <div className="col-6 col-md-9">
            <div className="mobile-menu">{renderMobileMenuOverlay()}</div>

            <nav className="nav">
              <ul className="nav-list">
                {navItems.map(navItem => {
                  const { id, isActive, text, url } = navItem;
                  return (
                    <li className="nav-item" key={id}>
                      <TertiarySolidLink
                        className={cn('nav-link', {
                          'nav-link--active': isActive
                        })}
                        dataKey={id}
                        href={url}
                      >
                        {text}
                      </TertiarySolidLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Heading id="NewAndOnOrder" size="large" tagName="h1" variant>
              {pageTitle}
            </Heading>
          </div>
        </div>
      </header>

      <article className="row">
        <div className="col-md-12">
          <Tabs ariaLabelledBy="#NewAndOnOrder" asNav centered options={tabOptions} />
        </div>
      </article>
    </div>
  );
};

BrowseTitlesPage.propTypes = {
  children: childrenShape.isRequired,
  displayBibs: PropTypes.arrayOf(displayBibShape).isRequired,
  isFetching: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  pageTitle: PropTypes.string.isRequired,
  pagination: paginationShape.isRequired,
  renderActiveFilters: PropTypes.func.isRequired,
  renderFacets: PropTypes.func.isRequired,
  renderMobileMenuOverlay: PropTypes.func.isRequired,
  tabLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BrowseTitlesPage;
