import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { SUB_CATEGORIES_ORDER as EVENTS_SUB_CATEGORIES_ORDER } from 'app/constants/EventsConstants';
import { ALL_FACET_FIELD_IDS, FILTER_TYPE } from 'app/constants/FacetsConstants';

export const filterShape = ImmutablePropTypes.mapContains({
  applied: PropTypes.bool,
  count: PropTypes.number,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});

export const activeFiltersShape = ImmutablePropTypes.mapOf(
  ImmutablePropTypes.listOf(filterShape),
  PropTypes.oneOf(ALL_FACET_FIELD_IDS)
);

export const categoriesShape = PropTypes.arrayOf(PropTypes.oneOf(ALL_FACET_FIELD_IDS));

export const facetFieldsShape = ImmutablePropTypes.listOf(
  ImmutablePropTypes.mapContains({
    collapsed: PropTypes.bool.isRequired,
    id: PropTypes.oneOf(ALL_FACET_FIELD_IDS).isRequired,
    groups: ImmutablePropTypes.listOf(
      ImmutablePropTypes.mapContains({
        filters: ImmutablePropTypes.listOf(
          ImmutablePropTypes.mapContains({
            applied: PropTypes.bool.isRequired,
            count: PropTypes.number,
            label: PropTypes.string.isRequired,
            localization: PropTypes.string,
            value: PropTypes.string.isRequired
          })
        ).isRequired,
        groupId: PropTypes.oneOf(ALL_FACET_FIELD_IDS.concat(EVENTS_SUB_CATEGORIES_ORDER)),
        labelId: PropTypes.string
      })
    )
  })
);

export const fieldIdShape = PropTypes.oneOf(Object.values(ALL_FACET_FIELD_IDS));

export const filterTypeShape = PropTypes.oneOf(Object.values(FILTER_TYPE));
