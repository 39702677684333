import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { WINDOW_WIDTH_MIN_PX } from 'app/constants/window';
import useWindowWidth from 'app/hooks/useWindowWidth';
import DropdownAdapter from './DropdownAdapter';
import { tabsOptionShape } from './shapes';
import Tabs from './Tabs';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;
const MAX_NUM_MOBILE_TABS = 2;

const TabsContainer = ({
  ariaLabel,
  ariaLabelledBy,
  asNav,
  centered,
  initialSelectedOptionId,
  onTabSelected,
  options
}) => {
  const initialActiveIndex = initialSelectedOptionId
    ? options.findIndex(option => option.id === initialSelectedOptionId)
    : 0;

  const history = useHistory();
  const { pathname } = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveIndex);
  const [focusableTabIndex, setFocusableTabIndex] = useState(initialActiveIndex);
  const tabRefs = useRef([]);
  const windowWidthPx = useWindowWidth();

  useEffect(() => {
    const { current: tabEls = [] } = tabRefs;

    const shouldChangeFocus = tabEls.some((tabEl, tabIndex) => {
      const isFocusable = focusableTabIndex === tabIndex;
      const isFocused = document.activeElement === tabEl;

      return !isFocusable && isFocused;
    });

    if (shouldChangeFocus) {
      tabEls[focusableTabIndex].focus();
    }
  }, [focusableTabIndex]);

  if (asNav && !options.every(option => Boolean(option.url))) {
    throw new Error('Every tab option must have a url in order to render the tabs as a nav');
  }
  const activeNavTabIndex = asNav ? options.findIndex(option => option.url === pathname) : -1;

  const hasDropdownWhenMobile = options.length > MAX_NUM_MOBILE_TABS;
  const numTabs = (options ?? []).length;

  const handleDropdownSelect = optionId => {
    const selectedOptionIndex = options.findIndex(option => option.id === optionId);
    if (selectedOptionIndex === -1) {
      return;
    }

    if (asNav) {
      const selectedOption = options[selectedOptionIndex];
      history.push(selectedOption.url);
    } else {
      setActiveTabIndex(selectedOptionIndex);
      setFocusableTabIndex(selectedOptionIndex);
      onTabSelected?.(options[selectedOptionIndex]);
    }
  };

  const handleTabBlur = () => {
    setTimeout(() => {
      const { current: tabEls } = tabRefs;

      const hasFocusLeftTabList = !Array.from(tabEls).some(tabEl => {
        return tabEl === document.activeElement;
      });

      if (hasFocusLeftTabList) {
        setFocusableTabIndex(asNav ? activeNavTabIndex : activeTabIndex);
      }
    }, 0);
  };

  const handleTabClick = tabIndex => {
    const selectedOption = options[tabIndex];

    setActiveTabIndex(tabIndex);
    setFocusableTabIndex(tabIndex);

    onTabSelected?.(selectedOption);
  };

  const handleTabKeyDown = event => {
    const keyCode = event.which;

    switch (keyCode) {
      case KEY_CODE_ARROW_LEFT: {
        let newIndex = focusableTabIndex - 1;

        if (newIndex < 0) {
          newIndex = numTabs - 1;
        }

        setFocusableTabIndex(newIndex);
        break;
      }
      case KEY_CODE_ARROW_RIGHT: {
        let newIndex = focusableTabIndex + 1;

        if (newIndex > numTabs - 1) {
          newIndex = 0;
        }

        setFocusableTabIndex(newIndex);
        break;
      }
      default:
        break;
    }
  };

  const isRenderDropdown = hasDropdownWhenMobile && windowWidthPx < WINDOW_WIDTH_MIN_PX.MEDIUM;

  return (
    <>
      {isRenderDropdown ? (
        <>
          <DropdownAdapter
            activeOptionIndex={asNav ? activeNavTabIndex : activeTabIndex}
            centered={centered}
            handleSelect={handleDropdownSelect}
            options={options}
          />
        </>
      ) : (
        <Tabs
          activeTabIndex={asNav ? activeNavTabIndex : activeTabIndex}
          ariaLabel={ariaLabel}
          ariaLabelledBy={ariaLabelledBy}
          asNav={asNav}
          centered={centered}
          focusableTabIndex={focusableTabIndex}
          handleTabBlur={handleTabBlur}
          handleTabClick={handleTabClick}
          handleTabKeyDown={handleTabKeyDown}
          options={options}
          tabRefs={tabRefs}
        />
      )}
    </>
  );
};

TabsContainer.defaultProps = {
  asNav: false,
  centered: false,
  onTabSelected: () => null
};

TabsContainer.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  asNav: PropTypes.bool,
  centered: PropTypes.bool,
  initialSelectedOptionId: PropTypes.string,
  onTabSelected: PropTypes.func,
  options: PropTypes.arrayOf(tabsOptionShape).isRequired
};

export default TabsContainer;
