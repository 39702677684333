import { SEARCH_FACET_FIELD_ID } from 'app/constants/BibConstants';
import { EVENTS_FACET_FIELDS, MY_EVENTS_FACET_ID } from 'app/constants/EventsConstants';

export const ALL_FACET_FIELD_IDS = Object.values(SEARCH_FACET_FIELD_ID).concat(
  Object.values(EVENTS_FACET_FIELDS),
  Object.values(MY_EVENTS_FACET_ID)
);

export const FILTER_TYPE = {
  BROWSE: 'browse',
  EVENT: 'event'
};

export const RADIO_BUTTON_FILTER = 'RADIO_BUTTON';
