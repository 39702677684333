import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  appliesToResourcePart: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  uris: ImmutablePropTypes.listOf(PropTypes.string).isRequired
});
