import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  // TODO: Discovery API returns display versions of Provider Names. Populate as needed.
  providerName: PropTypes.oneOf(['NoveList']).isRequired,
  providerURI: PropTypes.string.isRequired
});
