import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { tabsOptionShape } from './shapes';
import './Tabs.scss';

const Tabs = ({
  activeTabIndex,
  ariaLabel,
  ariaLabelledBy,
  asNav,
  centered: isCentered,
  focusableTabIndex,
  handleTabBlur,
  handleTabClick,
  handleTabKeyDown,
  options,
  tabRefs,
  uniqueIdPrefix
}) => {
  if (!(ariaLabel || ariaLabelledBy)) {
    // eslint-disable-next-line no-console
    console.warn('Either the ariaLabel or ariaLabelledBy prop should have a non-falsy value');
  }

  const makePanelId = tabIndex => `${uniqueIdPrefix}_Panel_${tabIndex}`;
  const maketabId = tabIndex => `${uniqueIdPrefix}_Tab_${tabIndex}`;

  const selectedOption = options[activeTabIndex];
  const { renderPanelBody } = selectedOption ?? { renderPanelBody: () => null };

  const wrapList = list => (asNav ? <nav>{list}</nav> : <>{list}</>);

  return (
    <div className="cp-core-tabs">
      {wrapList(
        <ul
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          className={cn('tabs__tab-list', {
            'tabs__tab-list--centered': isCentered
          })}
          role="tablist"
        >
          {options.map((option, tabIndex) => {
            const { id, label, url } = option;

            const isActive = tabIndex === activeTabIndex;
            const isFocusable = tabIndex === focusableTabIndex;
            const panelId = makePanelId(tabIndex);
            const tabId = maketabId(tabIndex);

            return (
              <li key={id}>
                {asNav ? (
                  <Link
                    aria-controls={panelId}
                    aria-selected={isActive}
                    className={cn('tabs__tab', { 'tabs__tab--active': isActive })}
                    id={tabId}
                    onBlur={handleTabBlur}
                    onKeyDown={handleTabKeyDown}
                    ref={el => (tabRefs.current[tabIndex] = el)}
                    role="tab"
                    tabIndex={isFocusable ? 0 : -1}
                    to={url}
                  >
                    {label}
                  </Link>
                ) : (
                  <button
                    aria-controls={panelId}
                    aria-selected={isActive}
                    className={cn('tabs__tab', { 'tabs__tab--active': isActive })}
                    id={tabId}
                    onBlur={handleTabBlur}
                    onClick={handleTabClick.bind(null, tabIndex)}
                    onKeyDown={handleTabKeyDown}
                    ref={el => (tabRefs.current[tabIndex] = el)}
                    role="tab"
                    tabIndex={isFocusable ? 0 : -1}
                    type="button"
                  >
                    {label}
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      )}
      <div aria-labelledby={maketabId(activeTabIndex)} id={makePanelId(activeTabIndex)} role="tabpanel" tabIndex={0}>
        {renderPanelBody()}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  asNav: false,
  uniqueIdPrefix: uniqueId('Tabs_')
};

Tabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  asNav: PropTypes.bool,
  centered: PropTypes.bool.isRequired,
  focusableTabIndex: PropTypes.number.isRequired,
  handleTabBlur: PropTypes.func.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  handleTabKeyDown: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(tabsOptionShape).isRequired,
  tabRefs: PropTypes.object.isRequired,
  uniqueIdPrefix: PropTypes.string
};

export default Tabs;
