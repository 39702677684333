import OverlayConstants from 'app/constants/OverlayConstants';

const closeOverlay = overlayKey => {
  return {
    type: OverlayConstants.CLOSE_OVERLAY,
    payload: {
      overlayKey
    }
  };
};

const openOverlay = overlayKey => {
  return {
    type: OverlayConstants.OPEN_OVERLAY,
    payload: {
      overlayKey
    }
  };
};

export { closeOverlay, openOverlay };
