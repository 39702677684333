import cn from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown, FlatDropdownButton } from '@bibliocommons/base-dropdown';

import './DropdownAdapter.scss';
import { tabsOptionShape } from './shapes';

const DropdownAdapter = ({ activeOptionIndex, centered: isCentered, handleSelect, options }) => {
  const dropdownOptions = Immutable.List(
    options.map((option, index) =>
      Immutable.Map({
        label: option.label,
        value: option.id,
        selected: index === activeOptionIndex
      })
    )
  );

  const selectedOption = options[activeOptionIndex];

  return (
    <div className="cp-tabs-dropdown-adapter">
      <Dropdown
        buttonTag={FlatDropdownButton}
        className={cn('tabs-dropdown-adapter__dropdown', {
          'tabs-dropdown-adapter__dropdown--centered': isCentered
        })}
        dataKey="tabs-dropdown-adapter"
        handleSelect={handleSelect}
        options={dropdownOptions}
        selectable
      />
      <div>{selectedOption?.renderPanelBody()}</div>
    </div>
  );
};

DropdownAdapter.defaultProps = {
  centered: false
};

DropdownAdapter.propTypes = {
  activeOptionIndex: PropTypes.number.isRequired,
  centered: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(tabsOptionShape)
};

export default DropdownAdapter;
