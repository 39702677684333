import PropTypes from 'prop-types';
import { LIST_ITEM_TYPE } from 'app/constants/list';

export default PropTypes.shape({
  annotation: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  metadataId: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(LIST_ITEM_TYPE)).isRequired,
  url: PropTypes.string
});
