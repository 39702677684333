import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextButton, Button } from '@bibliocommons/base-buttons';
import { ChevronDown, ChevronUp, Close, Ellipsis, EllipsisVertical } from '@bibliocommons/base-icons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import { intlShape } from 'app/shapes';

import './SystemMessages.scss';

const COLLAPSED_MAX_HEIGHT_PX = 24;

const SystemMessages = ({
  cmsEnabled,
  dismissNotice,
  intl,
  isDismissible,
  messages,
  systemMessageBackgroundColor,
  systemMessageTextColor
}) => {
  const [expandedMessageIds, setExpandedMessageIds] = useState([]);
  const [expandableMessageIds, setExpandableMessageIds] = useState([]);
  const [currentMessages, setCurrentMessages] = useState(messages);
  const messageRefs = useRef({});

  useEffect(() => {
    const determineIfExpandable = () => {
      const newExpandableIds = currentMessages
        .map(message => {
          const { id } = message;
          const messageContainer = messageRefs.current[id];
          const messageHeight = messageContainer ? messageContainer.scrollHeight : 0;

          return messageHeight > COLLAPSED_MAX_HEIGHT_PX ? id : null;
        })
        .filter(Boolean);

      setExpandableMessageIds(newExpandableIds);
    };

    const onWindowResize = debounce(determineIfExpandable, 100);

    window.addEventListener('resize', onWindowResize);

    setTimeout(determineIfExpandable, 0);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [currentMessages]);

  const handleToggleClick = id => {
    setExpandedMessageIds(prevIds => (prevIds.includes(id) ? prevIds.filter(msgId => msgId !== id) : [...prevIds, id]));
  };

  const handleCloseMessage = id => {
    if (dismissNotice) {
      dismissNotice(id);
    }
    setCurrentMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
  };

  return currentMessages.length > 0 ? (
    <ul className="cp-system-messages" aria-label={intl.formatMessage({ id: 'label_system_messages' })}>
      {currentMessages.map((message, index) => {
        const { id, message: messageText } = message;
        if (!messageText) {
          return null;
        }

        const isExpanded = expandedMessageIds.includes(id);
        const isExpandable = expandableMessageIds.includes(id);
        const uniqueIdPrefix = `system-message-${id}`;
        const messageNumber = index + 1;

        return (
          <li
            key={id}
            className={`system-message ${isExpanded ? 'system-message--expanded' : ''}`}
            style={{ backgroundColor: cmsEnabled ? systemMessageBackgroundColor : undefined }}
          >
            <div className="message-container">
              <div
                className={`system-message__content ${isExpanded ? 'system-message__content--expanded' : ''}`}
                id={`${uniqueIdPrefix}_Expandable`}
                ref={el => (messageRefs.current[id] = el)}
                style={{ maxHeight: isExpanded ? 'none' : `${COLLAPSED_MAX_HEIGHT_PX}px` }}
              >
                {/* eslint-disable react/no-danger */}
                <div
                  className="message-text"
                  style={{ color: cmsEnabled ? systemMessageTextColor : undefined }}
                  dangerouslySetInnerHTML={{ __html: messageText }}
                />
                {/* eslint-enable react/no-danger */}
              </div>

              {isExpandable && (
                <TextButton
                  aria-hidden="true"
                  tabIndex="-1"
                  aria-expanded={isExpanded}
                  aria-controls={`${uniqueIdPrefix}_Expandable`}
                  className="toggle"
                  dataKey="is-expanded-toggle"
                  handleClick={() => handleToggleClick(id)}
                  leftAligned
                  type="button"
                  style={{ color: cmsEnabled ? systemMessageTextColor : undefined }}
                >
                  <div className="toggle-text">
                    <span className="toggle-text__label">
                      <FormattedMessage id={isExpanded ? 'read_less' : 'read_more'} />
                      {isExpanded ? <ChevronUp /> : <ChevronDown />}
                    </span>
                    <span className="toggle-text__icon">{isExpanded ? <EllipsisVertical /> : <Ellipsis />}</span>
                  </div>
                </TextButton>
              )}

              {isDismissible && (
                <Button
                  className="dismiss-button"
                  handleClick={() => handleCloseMessage(id)}
                  dataKey="dismiss-button"
                  style={{ color: cmsEnabled ? systemMessageTextColor : undefined }}
                >
                  <ScreenReaderMessage>
                    <FormattedMessage id="close_message" values={{ messageNumber }} />
                  </ScreenReaderMessage>
                  <Close />
                </Button>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  ) : null;
};

SystemMessages.propTypes = {
  cmsEnabled: PropTypes.bool.isRequired,
  dismissNotice: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isDismissible: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired
    })
  ).isRequired,
  systemMessageBackgroundColor: PropTypes.string,
  systemMessageTextColor: PropTypes.string
};

export default SystemMessages;
