import { SEARCH_FACET_FIELD_ID } from 'app/constants/BibConstants';
import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const BROWSE_ROW_TYPE = {
  BIB: 'BIB',
  LINK: 'LINK',
  LIST: 'LIST',
  NAV: 'NAV'
};

export const FACET_CATEGORIES = [
  SEARCH_FACET_FIELD_ID.FORMAT,
  SEARCH_FACET_FIELD_ID.CONTENT,
  SEARCH_FACET_FIELD_ID.AUDIENCE,
  SEARCH_FACET_FIELD_ID.LANGUAGE
];

export const NAV_ITEM_ID = {
  AWARDS: 'Awards',
  BEST_SELLERS: 'BestSellers',
  LANGUAGES: 'Languages',
  NEW_AND_ON_ORDER: 'NewAndOnOrder', // Not in Gateway's Settings response
  STAFF_PICKS: 'StaffPicks',
  TEACHER_PICKS: 'TeacherPicks'
};

export const SUB_PAGE_SLUG = {
  NEW: 'new',
  ON_ORDER: 'onorder'
};

export default createReduxConstants('BROWSE', {
  FETCH_BROWSE_REQUEST: null,
  FETCH_BROWSE_SUCCESS: null,
  FETCH_BROWSE_FAILURE: null
});
