import PropTypes from 'prop-types';

import displayBibShape from 'app/shapes/displayBib';
import ListShape from './listShape';
import UserShape from './userShape';

export default PropTypes.shape({
  bibsByMetadataId: PropTypes.objectOf(displayBibShape).isRequired,
  list: ListShape.isRequired,
  user: UserShape.isRequired
});
