function searchEvents(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/search`;
  return apiClient.get(route, { query });
}

function getEvents(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events`;
  return apiClient.get(route, { query });
}

function fetchEventsForBrochure(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/brochure`;
  return apiClient.get(route, { query });
}

function getMyEvents(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/registrations`;
  return apiClient.get(route, { query });
}

function cancelRegistration(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/registrations/cancel`;
  return apiClient.delete(route, query);
}

function updateRegistration(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/registrations`;
  return apiClient.put(route, query);
}

function saveRegistration(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/registrations`;
  return apiClient.post(route, query);
}

function getMyInterestedEvents(query, apiClient) {
  const route = apiClient.route`/libraries/${':librarySubdomain'}/events/registrations/interested`;
  return apiClient.get(route, { query });
}

export default {
  cancelRegistration,
  fetchEventsForBrochure,
  getEvents,
  getMyEvents,
  getMyInterestedEvents,
  saveRegistration,
  searchEvents,
  updateRegistration
};
