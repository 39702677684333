import Immutable from 'immutable';
import EventsConstants from '../../constants/EventsConstants';

const initialState = Immutable.fromJS({
  results: [],
  pagination: {},
  query: {},
  isFetching: false
});

export default function MyEvents(state = initialState, action) {
  switch (action.type) {
    case EventsConstants.MY_EVENTS_REQUEST: {
      return state.merge({
        isFetching: true
      });
    }

    case EventsConstants.MY_EVENTS_SUCCESS: {
      return state.merge({
        ...action.payload.events,
        query: action.query,
        isFetching: false
      });
    }

    case EventsConstants.MY_EVENTS_FAILURE: {
      return state.merge({
        isFetching: false
      });
    }

    case EventsConstants.MY_EVENTS_CANCEL_SUCCESS: {
      let newState = state;
      const { eventIds } = action.payload;
      const events = state.getIn(['results']);
      const count = state.getIn(['pagination', 'count']) - eventIds.length;
      const updatedEventsList = events.filter(val => !eventIds.includes(val));

      newState = newState.set('results', updatedEventsList);
      newState = newState.set('isFetching', false);
      newState = newState.setIn(['pagination', 'count'], count);

      return newState;
    }

    case EventsConstants.REMOVE_INTERESTED_EVENTS_SUCCESS: {
      const eventIds = state.get('results').filter(event => !action.payload.eventIds.includes(event));
      return state.merge({
        results: eventIds
      });
    }

    default:
      return state;
  }
}
