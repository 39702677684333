import PropTypes from 'prop-types';

import {
  AVAILABILITY_LOCALIZED_STATUS,
  AVAILABILITY_LOCATION_TYPE,
  AVAILABILITY_ROLLUP_STATUS
} from 'app/constants/list';

export default PropTypes.shape({
  locationType: PropTypes.oneOf(Object.values(AVAILABILITY_LOCATION_TYPE)),
  localizedStatus: PropTypes.oneOf(Object.values(AVAILABILITY_LOCALIZED_STATUS)),
  rollupStatus: PropTypes.oneOf(Object.values(AVAILABILITY_ROLLUP_STATUS))
});
