import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Heading from '@bibliocommons/heading';
import { useRouter } from '@bibliocommons/utils-routing';
import { Pill } from '@bibliocommons/pill';
import { TextButton } from '@bibliocommons/base-buttons';

import ShortFormattedDate from 'app/components/shared/ShortFormattedDate';
import { activeFiltersShape, categoriesShape, filterTypeShape } from 'app/shapes/facets';

import './ActiveFilters.scss';

export default function ActiveFilters({
  handleToggleFilter,
  activeFilters,
  categories,
  startDate,
  endDate,
  headingTag,
  filterType
}) {
  const router = useRouter();
  const filtersActive = !!activeFilters.size || startDate || endDate;

  const facetCategories = categories
    .map(field => {
      if (activeFilters.get(field)) {
        return {
          currentFilter: activeFilters.get(field),
          fieldId: field
        };
      }
      return null;
    })
    .filter(value => value);

  function renderDatePills() {
    return (
      <>
        {startDate && (
          <Pill handleDismiss={() => handleToggleFilter('startDate', Immutable.Map({ value: undefined }), true)}>
            <FormattedMessage id="date_facet_from" values={{ startDate: <ShortFormattedDate value={startDate} /> }} />
          </Pill>
        )}
        {endDate && (
          <Pill handleDismiss={() => handleToggleFilter('endDate', Immutable.Map({ value: undefined }), true)}>
            <FormattedMessage id="date_facet_to" values={{ endDate: <ShortFormattedDate value={endDate} /> }} />
          </Pill>
        )}
      </>
    );
  }

  function renderActivePillLabel(filter) {
    if (filter.get('localization')) {
      return <FormattedMessage id={filter.get('localization')} />;
    }
    return filter.get('label');
  }

  return filtersActive ? (
    <div className="cp-active-filters">
      <ScreenReaderMessage>
        <Heading tagName={headingTag}>
          <FormattedMessage id={`${filterType}_active_filters`} />
        </Heading>
      </ScreenReaderMessage>
      {renderDatePills()}
      {facetCategories.map(({ currentFilter, fieldId }) =>
        currentFilter.map(filter => (
          <Pill key={`pill-${filter.get('value')}`} handleDismiss={() => handleToggleFilter(fieldId, filter)}>
            {renderActivePillLabel(filter)}
          </Pill>
        ))
      )}
      <TextButton handleClick={() => router.replace({ query: null })} dataKey="clear-facets-button">
        <FormattedMessage id="clear_filters" />
      </TextButton>
    </div>
  ) : null;
}

ActiveFilters.propTypes = {
  activeFilters: activeFiltersShape.isRequired,
  categories: categoriesShape.isRequired,
  endDate: PropTypes.string,
  filterType: filterTypeShape.isRequired,
  handleToggleFilter: PropTypes.func.isRequired,
  headingTag: PropTypes.string,
  startDate: PropTypes.string
};
