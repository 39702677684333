import PropTypes from 'prop-types';

import { MENU_ITEM_LINK_TYPE } from 'app/constants/menuConstants';

export default PropTypes.shape({
  children: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      isActive: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      linkType: PropTypes.oneOf(Object.values(MENU_ITEM_LINK_TYPE)).isRequired,
      url: PropTypes.string.isRequired
    })
  ),
  icon: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  linkType: PropTypes.oneOf(Object.values(MENU_ITEM_LINK_TYPE)).isRequired,
  url: PropTypes.string
});
