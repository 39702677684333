import React from 'react';

import BrowsePage from 'app/components/browse/BrowsePage';
import { fetchBrowseConfig } from 'app/actions/BrowseActions';
import connectRoute from 'app/routes/connectRoute';

const getRouteData = ({ dispatch }) => {
  return [dispatch(fetchBrowseConfig())];
};

export function BrowsePageRoute() {
  return <BrowsePage />;
}

export default connectRoute(BrowsePageRoute, { getRouteData });
