import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { closeOverlay, openOverlay } from 'app/actions/OverlayActions';
import menuItemShape from 'app/shapes/menuItem';
import MobileMenuOverlay from './MobileMenuOverlay';

const MobileMenuOverlayContainer = ({ menuItems, overlayKey, title, triggerLabel }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isOpen = useSelector(state => state?.getIn(['ui', 'overlay', overlayKey, 'isOpen'])) ?? false;

  const close = () => {
    dispatch(closeOverlay(overlayKey));
  };

  const open = () => {
    dispatch(openOverlay(overlayKey));
  };

  return (
    <MobileMenuOverlay
      close={close}
      isOpen={isOpen}
      menuItems={menuItems}
      open={open}
      title={title}
      triggerLabel={triggerLabel}
      triggerScreenReaderText={`${triggerLabel}, ${intl.formatMessage({ id: 'opens_an_overlay' })}`}
    />
  );
};

MobileMenuOverlayContainer.propTypes = {
  menuItems: PropTypes.arrayOf(menuItemShape).isRequired,
  overlayKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  triggerLabel: PropTypes.string.isRequired
};

export default MobileMenuOverlayContainer;
