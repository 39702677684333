import { FILTER_PREFIX, FILTER_SEPARATOR } from 'app/constants/BibConstants';
import { FACET_CATEGORIES } from 'app/constants/BrowseConstants';

const serializeFieldIdForQuerystring = fieldId => {
  return fieldId.toLowerCase();
};

const mapCatalogSearchFieldsToActiveFilters = (catalogSearchFields, searchParams) => {
  return catalogSearchFields.reduce((acc, catalogSearchField) => {
    const { id: fieldId, fieldFilters } = catalogSearchField;

    const serializedFieldId = serializeFieldIdForQuerystring(fieldId);

    const activeFilters = fieldFilters
      .map(fieldFilter => {
        const { value: filterId } = fieldFilter;
        const isApplied = searchParams.getAll(serializedFieldId).includes(filterId);

        return {
          applied: isApplied,
          label: filterId,
          value: filterId
        };
      })
      .filter(({ applied: isApplied }) => isApplied);

    if (activeFilters.length) {
      acc[fieldId] = activeFilters;
    }

    return acc;
  }, {});
};

const mapCatalogSearchFieldsToFacetFields = (catalogSearchFields, searchParams, makeFilterLabel) => {
  return catalogSearchFields.map(catalogSearchField => {
    const { id: fieldId, fieldFilters } = catalogSearchField;

    return {
      collapsed: false,
      id: fieldId,
      groups: [
        {
          filters: fieldFilters.map(fieldFilter => {
            const { count, value: filterId } = fieldFilter;
            const serializedFieldId = serializeFieldIdForQuerystring(fieldId);
            const isApplied = searchParams.getAll(serializedFieldId).includes(filterId);
            const label = makeFilterLabel(fieldId, fieldFilter);

            return {
              applied: isApplied,
              count,
              label,
              value: filterId
            };
          }),
          groupId: fieldId,
          labelId: null
        }
      ],
      labelId: fieldId
    };
  });
};

const mapQuerySearchParamsToCatalogSearchFilters = searchParams => {
  const PAGE_KEY = 'page';
  const facetKeys = Object.values(FACET_CATEGORIES).map(serializeFieldIdForQuerystring);

  return Array.from(searchParams.keys()).reduce((acc, key) => {
    const isPageKey = key === PAGE_KEY;

    const isValidKey = isPageKey || facetKeys.includes(key);
    if (!isValidKey) {
      return acc;
    }

    if (isPageKey) {
      const pageNum = Number(searchParams.get(PAGE_KEY));

      if (Number.isInteger(pageNum)) {
        acc[PAGE_KEY] = pageNum;
      }
    } else {
      const serializedKey = FILTER_PREFIX.concat(key.toUpperCase());

      acc[serializedKey] = searchParams.getAll(key).join(FILTER_SEPARATOR);
    }

    return acc;
  }, {});
};

export {
  mapCatalogSearchFieldsToActiveFilters,
  mapCatalogSearchFieldsToFacetFields,
  mapQuerySearchParamsToCatalogSearchFilters,
  serializeFieldIdForQuerystring
};
