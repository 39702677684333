import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@bibliocommons/base-buttons';
import {
  FullScreenOverlayBody,
  FullScreenOverlayContent,
  FullScreenOverlayHeader
} from '@bibliocommons/base-full-screen-overlay';
import { ChevronRight } from '@bibliocommons/base-icons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import Menu from 'app/components/shared/Menu';
import { FullScreenOverlayContainer } from 'app/components/shared/FullScreenOverlay';
import menuItemShape from 'app/shapes/menuItem';

import './MobileMenuOverlay.scss';

const MobileMenuOverlay = ({ close, isOpen, menuItems, open, title, triggerLabel, triggerScreenReaderText }) => {
  return (
    <div className="cp-mobile-menu-overlay">
      <Button className="mobile-trigger" dataKey="mobile-menu-trigger" handleClick={open} type="button">
        <span className="mobile-trigger-text" aria-hidden>
          {triggerLabel}
        </span>
        <ScreenReaderMessage>{triggerScreenReaderText}</ScreenReaderMessage>
        <span className="icon-chevron">
          <ChevronRight />
        </span>
      </Button>

      <FullScreenOverlayContainer
        dataKey="sidebar-overlay"
        handleClose={close}
        open={isOpen}
        overlayKey="sidebar-overlay"
      >
        <FullScreenOverlayBody>
          <FullScreenOverlayHeader title={title} />
          <FullScreenOverlayContent size="small">
            <Menu menuItems={menuItems} />
          </FullScreenOverlayContent>
        </FullScreenOverlayBody>
      </FullScreenOverlayContainer>
    </div>
  );
};

MobileMenuOverlay.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(menuItemShape).isRequired,
  open: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  triggerLabel: PropTypes.string.isRequired,
  triggerScreenReaderText: PropTypes.string.isRequired
};

export default MobileMenuOverlay;
