import Immutable from 'immutable';
import keyBy from 'lodash/keyBy';

import { threeLetterToTwoLetterLangCodes } from '@bibliocommons/constants-languages';
import { stripTags } from '@bibliocommons/utils-html';

import { AUDIENCE, CONTENT_TYPE, FORMAT_ID } from 'app/constants/displayBib';
import { BIB_IMAGE_INFO_TYPE, BIB_TITLE_TYPE, LIST_ITEM_TYPE, LIST_TYPE } from 'app/constants/list';

// adapts new user shape to that expected by UserCard
const adaptUserToUserCardUser = user => {
  return Immutable.Map({
    active: user.isActive,
    avatar: user.avatarImagePath,
    id: Number(user.userId),
    homeLibraryFullName: user.homeLibraryFullName ?? '',
    name: user.displayName,
    staff: user.isStaff
  });
};

// returns domain of given url
const extractDomain = url => {
  try {
    const hostname = new URL(url).hostname;
    const parts = hostname.split('.');

    // If there are more than two parts, it's likely to have a subdomain
    if (parts.length > 2) {
      return parts.slice(-2).join('.');
    }

    return hostname;
  } catch (error) {
    return url;
  }
};

const getBibDescription = rawBib => {
  const PROVIDER_ID = {
    CONTENT_CAFE: 'content cafe',
    HOOPLA: 'hoopla',
    MARC_21: 'marc 21',
    SYNDETICS: 'syndetics'
  };

  const summaryByProviderId = (rawBib.summaries || []).reduce((acc, summary) => {
    const providerId = (summary?.provider?.name ?? '').toLowerCase();
    if (!providerId) {
      return acc;
    }

    if (!Object.values(PROVIDER_ID).includes(providerId)) {
      // eslint-disable-next-line no-console
      console.warn(`Unknown Bib Summary Provider ID: ${providerId}`);
      return acc;
    }

    acc[providerId] = summary?.contents ?? '';

    return acc;
  }, {});

  return (
    summaryByProviderId[PROVIDER_ID.SYNDETICS] ||
    summaryByProviderId[PROVIDER_ID.CONTENT_CAFE] ||
    summaryByProviderId[PROVIDER_ID.MARC_21] ||
    null
  );
};

const mapRawBibToCatalogBib = rawBib => {
  const coverImageInfo =
    (rawBib.coverImageInfo ?? []).find(imageInfo => imageInfo.imageType === BIB_IMAGE_INFO_TYPE.LOCAL) ??
    (rawBib.coverImageInfo ?? []).find(imageInfo => imageInfo.imageType === BIB_IMAGE_INFO_TYPE.NONLOCAL) ??
    null;

  const coverImage = coverImageInfo
    ? {
        large: coverImageInfo.largeImageLink,
        medium: coverImageInfo.mediumImageLink,
        providerURI: coverImageInfo.providerURI,
        small: coverImageInfo.smallImageLink,
        type: coverImageInfo.imageType
      }
    : null;

  const titleRecord =
    (rawBib.titles ?? []).find(record => record.type === BIB_TITLE_TYPE.MANIFESTATION) ?? rawBib.titles?.[0];

  return {
    brief: {
      coverImage,
      creators: (rawBib.creators || []).map(rawCreator => {
        return {
          fullName: rawCreator.creator?.name?.fullName ?? null,
          // We do not have the numerical ID that comes in the Catalog Bib response.
          id: rawCreator.creator?.name?.fullName ?? null,
          otherScriptFullName: rawCreator.creator?.otherScriptFullNames?.[0]?.content ?? null,
          otherScriptSearchQuery: rawCreator.search?.otherScriptSearchQueries?.[0]?.content ?? null,
          relationships: rawCreator.creator?.relationships ?? [],
          searchType: rawCreator.search?.searchType ?? null
        };
      }),
      description: getBibDescription(rawBib),
      format: rawBib.format,
      otherScriptSubTitle: titleRecord?.subTitleOtherScripts?.[0]?.content ?? null,
      otherScriptTitle: titleRecord?.mainTitleOtherScripts?.[0]?.content ?? null,
      primaryLanguage: (rawBib.languages || [])?.[0] ?? null,
      subTitle: titleRecord?.subTitle ?? null,
      title: titleRecord?.mainTitle ?? null
    },
    metadataId: rawBib.metadataId
  };
};

const mapDisplayBibToSearchBib = (displayBib, { callNumber, fulfillmentPolicy }) => {
  const { authors, formatId, metadataId, title } = displayBib;

  return {
    id: metadataId,
    briefInfo: {
      audiences: [displayBib.audience],
      authors,
      callNumber,
      contentType: displayBib.contentType,
      format: formatId,
      groupKey: displayBib.groupKey,
      metadataId,
      title
    },
    policy: {
      provider: fulfillmentPolicy?.provider ?? null
    }
  };
};

const getSchemaOrgGraph = ({ bibsByMetadataId, canonicalListUrl, creatorLibraryHost, items, list }) => {
  const { description: listDescription, title: listTitle } = list ?? {};

  const safeDescription = stripTags(listDescription ?? '');

  return {
    '@graph': [
      {
        '@context': 'http://schema.org',
        '@type': 'ItemList',
        description: safeDescription,
        itemListElement: items.map((item, index) => {
          const { metadataId, title: itemTitle, type, url: itemUrl } = item;

          const displayBib = type === LIST_ITEM_TYPE.BIB ? bibsByMetadataId?.[metadataId] ?? null : null;

          const description = type === LIST_ITEM_TYPE.BIB ? stripTags(displayBib?.description ?? '') : null;
          const image = type === LIST_ITEM_TYPE.BIB ? displayBib?.imageUrl ?? null : null;
          const name = type === LIST_ITEM_TYPE.BIB ? displayBib.title ?? null : itemTitle;
          const url = type === LIST_ITEM_TYPE.BIB ? `${creatorLibraryHost}/v2/record/${metadataId}` : itemUrl;

          return {
            '@type': 'ListItem',
            description,
            image,
            name,
            position: index + 1,
            url
          };
        }),
        name: listTitle,
        numberOfItems: items.length,
        url: canonicalListUrl
      }
    ]
  };
};

const isValidListId = candidateListId => {
  return typeof candidateListId === 'string' && /^[0-9]+$/.test(candidateListId);
};

const mapDisplayBibToAnalyticsBibEntity = (displayBib, availability) => {
  return {
    bib_audience: displayBib.audience,
    bib_availability_status: availability?.localizedStatus ?? null,
    bib_fiction_type: displayBib.contentType,
    bib_format: displayBib.formatId,
    bib_fulfillment_provider: displayBib.fulfillmentProvider,
    bib_group_key: displayBib.groupKey,
    bib_hold_count: availability?.numCopiesHeld ?? null,
    bib_metadata_id: displayBib.metadataId,
    bib_total_item_count: availability?.numCopiesTotal ?? null
  };
};

const mapListToAnalyticsListEntity = list => {
  if (!list) {
    return null;
  }

  return {
    list_id: list.listId,
    list_purpose: list.type,
    list_title: list.title,
    list_visibility: list.visibilityType
  };
};

const mapListTypeToMessageId = listType => `user_list_purpose_${listType}`;

const mapRawBibToDisplayBib = (rawBib, { callNumber, fulfillmentPolicy }) => {
  if (!Object.values(FORMAT_ID).includes(rawBib.format)) {
    // eslint-disable-next-line no-console
    console.warn(`${rawBib.format} is not a known bib format id`);
  }

  const authors = (rawBib.creators ?? []).map(creator => {
    return creator.creator?.name.otherScriptFullNames?.[0]?.content || creator?.creator?.name?.fullName || '';
  });

  const coverImageInfo =
    (rawBib.coverImageInfo ?? []).find(imageInfo => imageInfo.imageType === BIB_IMAGE_INFO_TYPE.LOCAL) ??
    (rawBib.coverImageInfo ?? []).find(imageInfo => imageInfo.imageType === BIB_IMAGE_INFO_TYPE.NONLOCAL) ??
    null;
  const imageUrl = coverImageInfo?.largeImageLink ?? null;

  const titleRecord =
    (rawBib.titles ?? []).find(record => record.type === BIB_TITLE_TYPE.MANIFESTATION) ?? rawBib.titles?.[0];
  const subtitle = titleRecord?.subTitleOtherScripts?.[0]?.content || titleRecord?.subTitle || '';
  const title = titleRecord?.mainTitleOtherScripts?.[0]?.content || titleRecord?.mainTitle || '';

  return {
    audience: rawBib.audiences?.[0] ?? AUDIENCE.UNKNOWN,
    authors,
    callNumber,
    contentType: rawBib.contentType ?? CONTENT_TYPE.UNDETERMINED,
    description: getBibDescription(rawBib),
    formatId: rawBib.format,
    fulfillmentProvider: fulfillmentPolicy?.provider ?? null,
    groupKey: rawBib.legacyGrouping?.groupKey?.text ?? '',
    imageUrl,
    languageCode: threeLetterToTwoLetterLangCodes?.[rawBib.languages?.[0]] || threeLetterToTwoLetterLangCodes.eng,
    metadataId: rawBib.metadataId,
    publicationStatement: rawBib.publicationInfo?.[0]?.statement || '',
    subtitle,
    title
  };
};

const mapRawListItem = rawListItem => {
  if (!Object.values(LIST_ITEM_TYPE).includes(rawListItem.type)) {
    throw new Error(`Unknown list item type: ${rawListItem.type}`);
  }

  const isBibType = rawListItem.type === LIST_ITEM_TYPE.BIB;

  const itemId = String(rawListItem.id);
  const metadataId = rawListItem.bibListItem?.id ?? null;
  if (isBibType && !metadataId) {
    throw new Error(`list item of bib type is missing metadataId, item id: ${itemId}`);
  }

  return {
    annotation: rawListItem.annotation,
    itemId,
    metadataId,
    title: isBibType ? null : rawListItem?.urlListItem?.url?.title ?? '',
    type: rawListItem.type,
    url: isBibType ? null : rawListItem?.urlListItem?.url?.url ?? null
  };
};

const mapRawLike = rawLike => {
  if (!rawLike) {
    return null;
  }

  return {
    contentId: String(rawLike.contentId),
    likeId: rawLike.id,
    userId: String(rawLike.ownerId)
  };
};

const mapRawList = rawList => {
  if (!Object.values(LIST_TYPE).includes(rawList.listType)) {
    // eslint-disable-next-line no-console
    throw new Error(`${rawList.listType} is not a valid list type`);
  }

  return {
    items: rawList.items.map(mapRawListItem),
    list: {
      listId: String(rawList.id),
      relatedMetadataId: rawList.anchorBib?.id ?? null,
      userId: String(rawList.owner),
      createdTimestamp: new Date(rawList.created).getTime(),
      description: rawList.description,
      imageUrl: rawList.imageUrl,
      isRanked: rawList.isRanked,
      title: rawList.name,
      type: rawList.listType,
      updatedTimestamp: new Date(rawList.updated).getTime(),
      visibilityType: rawList.visibility
    }
  };
};

const mapRawUserToListUser = rawUser => {
  if (!rawUser) {
    return null;
  }

  return {
    avatarImagePath: rawUser.avatar,
    displayName: rawUser.name,
    homeLibraryFullName: rawUser.homeLibraryFullName,
    homeLibrarySubdomain: rawUser.homeLibrarySubdomain,
    isActive: rawUser.active,
    isStaff: rawUser.staff,
    userId: String(rawUser.id)
  };
};

const mapFetchBibItemAvailabilitiesResponse = rawResponseBody => {
  return Object.keys(rawResponseBody).reduce((acc, metadataId) => {
    const rawAvailability = rawResponseBody[metadataId];
    if (!rawAvailability) {
      return acc;
    }

    acc[metadataId] = {
      localizedStatus: rawAvailability.localisedStatus,
      locationType: rawAvailability.availabilityLocationType,
      numCopiesHeld: rawAvailability.heldCopies,
      numCopiesTotal: rawAvailability.totalCopies,
      rollupStatus: rawAvailability.status
    };

    return acc;
  }, {});
};

const mapManifestationsByMetadataId = rawManifestationsByMetadataId => {
  return Object.keys(rawManifestationsByMetadataId).reduce((acc, metadataId) => {
    acc[metadataId] = rawManifestationsByMetadataId[metadataId].map(manifestation => manifestation.id);
    return acc;
  }, {});
};

const mapFetchItemsResponse = fetchItemsResponse => {
  const {
    bibs: rawBibs,
    callNumbers,
    entities,
    items: rawItems,
    manifestationsByMetadataId = {},
    pagination,
    policies: fulfillmentPolicies
  } = fetchItemsResponse;

  const bibsByMetadataId = rawBibs.reduce((acc, rawBib) => {
    const metadataId = rawBib.metadataId;
    const callNumber = callNumbers?.[metadataId] ?? null;
    const fulfillmentPolicy = fulfillmentPolicies?.[metadataId] ?? null;

    acc[metadataId] = mapRawBibToDisplayBib(rawBib, { callNumber, fulfillmentPolicy });

    return acc;
  }, {});

  const catalogBibs = rawBibs.map(mapRawBibToCatalogBib);

  return {
    bibsByMetadataId,
    catalogBibsByMetadataId: keyBy(catalogBibs, 'metadataId'),
    entities: {
      ...entities,
      bibs: keyBy(
        Object.values(bibsByMetadataId).map(bib => {
          const metadataId = bib.metadataId;
          const callNumber = callNumbers?.[metadataId] ?? null;
          const fulfillmentPolicy = fulfillmentPolicies?.[metadataId] ?? null;

          return mapDisplayBibToSearchBib(bib, {
            callNumber,
            fulfillmentPolicy
          });
        }),
        'id'
      )
    },
    manifestationsByMetadataId: mapManifestationsByMetadataId(manifestationsByMetadataId),
    items: rawItems.map(mapRawListItem),
    pagination
  };
};

const mapFetchListResponse = fetchListResponse => {
  const {
    bibs: rawBibs,
    callNumbers,
    currentUser: rawCurrentUser,
    entities,
    list: rawList,
    manifestationsByMetadataId = {},
    numLikes,
    pagination,
    policies: fulfillmentPolicies,
    user: rawUser
  } = fetchListResponse;

  const bibsByMetadataId = rawBibs.reduce((acc, rawBib) => {
    const metadataId = rawBib.metadataId;
    const callNumber = callNumbers?.[metadataId] ?? null;
    const fulfillmentPolicy = fulfillmentPolicies?.[metadataId] ?? null;

    acc[metadataId] = mapRawBibToDisplayBib(rawBib, { callNumber, fulfillmentPolicy });

    return acc;
  }, {});

  const catalogBibs = rawBibs.map(mapRawBibToCatalogBib);
  const follow = rawCurrentUser?.follow;
  const ignore = rawCurrentUser?.ignore;
  const like = mapRawLike(rawCurrentUser?.like ?? null);
  const { items, list } = mapRawList(rawList);
  const user = mapRawUserToListUser(rawUser);

  return {
    bibsByMetadataId,
    catalogBibsByMetadataId: keyBy(catalogBibs, 'metadataId'),
    entities: {
      ...entities,
      bibs: keyBy(
        Object.values(bibsByMetadataId).map(bib => {
          const metadataId = bib.metadataId;
          const callNumber = callNumbers?.[metadataId] ?? null;
          const fulfillmentPolicy = fulfillmentPolicies?.[metadataId] ?? null;

          return mapDisplayBibToSearchBib(bib, {
            callNumber,
            fulfillmentPolicy
          });
        }),
        'id'
      ),
      followedUsers: follow ? keyBy([follow], 'ownerId') : null,
      ignoredUsers: ignore ? keyBy([ignore], 'ownerId') : null,
      users: keyBy([rawUser], 'id')
    },
    items,
    like,
    list,
    manifestationsByMetadataId: mapManifestationsByMetadataId(manifestationsByMetadataId),
    numLikes: numLikes || 0,
    pagination,
    user
  };
};

export {
  adaptUserToUserCardUser,
  extractDomain,
  getSchemaOrgGraph,
  isValidListId,
  mapDisplayBibToAnalyticsBibEntity,
  mapListToAnalyticsListEntity,
  mapFetchBibItemAvailabilitiesResponse,
  mapFetchItemsResponse,
  mapFetchListResponse,
  mapListTypeToMessageId,
  mapRawBibToCatalogBib,
  mapRawLike
};
