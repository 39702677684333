import PropTypes from 'prop-types';

const tabsOptionShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  renderPanelBody: PropTypes.func.isRequired,
  url: PropTypes.string
});

export { tabsOptionShape };
