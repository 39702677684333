import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import Context from '@bibliocommons/context';

import NoticeActions from 'app/actions/NoticeActions';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import { selectHiddenNotices } from 'app/selectors/NoticeSelector';
import { selectBranding } from 'app/selectors/BrandingSelector';
import SystemMessages from './SystemMessages';

const SystemMessagesContainer = () => {
  const DESTINATION = 'CORE';
  const PRIORITY = 'CRITICAL';

  const dispatch = useDispatch();
  const intl = useIntl();
  const branding = useSelector(selectBranding);
  const currentLibrary = useSelector(selectCurrentLibrary);
  const cmsEnabled = currentLibrary && currentLibrary.get('cmsEnabled');
  const apiGatewayUrl = useSelector(state => state.getIn(['app', 'apiGatewayURL']));
  const libraryDomain = useSelector(state => state.getIn(['app', 'libraryDomain']));
  const localBranch = useSelector(state => !!state.getIn(['app', 'localBranch']));
  const hiddenNotices = useSelector(selectHiddenNotices);
  const { mobileApp: isMobileApp } = useContext(Context);

  const noticeActions = bindActionCreators(NoticeActions, dispatch);

  const systemMessageBackgroundColor = branding.get('systemMessageBackgroundColor');
  const systemMessageTextColor = branding.get('systemMessageTextColor');

  const [messages, setMessages] = useState([]);
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;
    const fetchData = async () => {
      if (!apiGatewayUrl || !libraryDomain || isMobileApp) {
        return;
      }
      const res = await fetch(
        `${apiGatewayUrl}/libraries/${libraryDomain}/systemMessages?destination=${DESTINATION}&priority=${PRIORITY}`
      );
      if (!res.ok) {
        return;
      }
      const response = await res.json();

      if (isMountedRef.current) {
        const { entities, systemMessages } = response;
        const { systemMessages: messageEntities } = entities;
        const { items: messageIds } = systemMessages;

        let fetchedMessages = messageIds.map(messageId => messageEntities[messageId]).filter(Boolean);

        fetchedMessages = fetchedMessages.filter(message => {
          const messageId = message.id.toString();
          return !hiddenNotices.has(messageId);
        });

        fetchedMessages.sort((a, b) => moment(b.updatedDateTime).diff(moment(a.updatedDateTime)));

        setMessages(fetchedMessages);
      }
    };
    fetchData();

    return () => {
      isMountedRef.current = false;
    };
  }, [apiGatewayUrl, isMobileApp, libraryDomain, hiddenNotices]);

  if (messages.length === 0) {
    return null;
  }

  return isMobileApp ? null : (
    <SystemMessages
      cmsEnabled={cmsEnabled}
      dismissNotice={noticeActions.dismissNotice}
      intl={intl}
      isDismissible={!localBranch}
      messages={messages}
      systemMessageBackgroundColor={systemMessageBackgroundColor}
      systemMessageTextColor={systemMessageTextColor}
    />
  );
};

export default SystemMessagesContainer;
