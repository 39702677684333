import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import BrowseTitlesPage from 'app/components/browse/titles/BrowseTitlesPage';
import ErrorPage from 'app/components/ErrorPage';
import MainLayout from 'app/components/layouts/MainLayout';
import { SUB_PAGE_SLUG } from 'app/constants/BrowseConstants';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import BrowsePageRoute from './BrowsePageRoute';
import BrowseTitlesNewPageRoute from './BrowseTitlesNewPageRoute';
import BrowseTitlesOnOrderPageRoute from './BrowseTitlesOnOrderPageRoute';

export default (
  <Route path="/v2">
    <MainLayout>
      <Switch>
        <Route exact path="/v2">
          <Redirect to="/v2/browse" />
        </Route>
        <Route exact path="/v2/browse">
          <BrowsePageRoute />
        </Route>

        <Route path="/v2/browse/titles">
          <Route exact path="/v2/browse/titles">
            <Redirect exact from="/v2/browse/titles" to={`/v2/browse/titles/${SUB_PAGE_SLUG.NEW}`} />
          </Route>
          <BrowseTitlesPage>
            <Route exact path={`/v2/browse/titles/${SUB_PAGE_SLUG.NEW}`}>
              <BrowseTitlesNewPageRoute />
            </Route>
            <Route exact path={`/v2/browse/titles/${SUB_PAGE_SLUG.ON_ORDER}`}>
              <BrowseTitlesOnOrderPageRoute />
            </Route>
          </BrowseTitlesPage>
        </Route>

        <Route>
          <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
