import AirbrakeClient from 'airbrake-js';
import { detect } from 'detect-browser';

export default {
  initialize(airbrakeConfig = {}) {
    if (airbrakeConfig.enabled) {
      const { projectKey, projectId, env, appVersion } = airbrakeConfig;

      const client = new AirbrakeClient({
        projectId,
        projectKey,
        environment: env,
        version: appVersion
      });

      // Conditional browser filtering
      const browser = detect();
      const version = parseInt(browser?.version || '0', 10);
      const name = browser?.name;
      const unsupportedBrowsers = ['safari', 'ie', 'ios'];

      if (unsupportedBrowsers.includes(name) && version < 11) {
        client.addFilter(() => null); // nullify notices for old browsers
      }

      this.client = client;
      this.notify = err => {
        if (!err) return Promise.reject(new Error('Undefined error object'));
        return client.notify(err).then(notice => notice?.id);
      };
    } else {
      // Console error logging
      this.client = { addFilter: () => {} };
      this.notify = err => {
        /* eslint-disable */
        console.error(err);
        /* eslint-enable */
        return Promise.resolve('[logged to console]');
      };
    }
  }
};
