import { performCatalogSearch } from 'app/actions/SearchActions';
import { mapQuerySearchParamsToCatalogSearchFilters } from 'app/helpers/browse';
import connectRoute from 'app/routes/connectRoute';

const BrowseTitlesNewPageRoute = () => null;

const getRouteData = ({ dispatch, location }) => {
  const searchParams = new URLSearchParams(location.search);
  const catalogSearchFilters = mapQuerySearchParamsToCatalogSearchFilters(searchParams);

  return [
    dispatch(
      performCatalogSearch({
        f_ON_ORDER: 'false',
        limit: 18,
        query: 'nw:[0 TO 180]',
        searchType: 'bl',
        sort: 'NEWLY_ACQUIRED',
        ...catalogSearchFilters
      })
    )
  ];
};

export default connectRoute(BrowseTitlesNewPageRoute, {
  debounceOptions: {
    wait: 500 // milliseconds
  },
  getRouteData
});
