import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  otherScriptFullName: PropTypes.string,
  relationships: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  searchType: PropTypes.oneOf(['author']).isRequired,
  searchQuery: PropTypes.string.isRequired,
  otherScriptSearchQuery: PropTypes.string
});
