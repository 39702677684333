import PropTypes from 'prop-types';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { childrenShape } from '@bibliocommons/bc-prop-types';

const Toggle = ({ drawerActions, children }) => {
  const swipeHandlers = useSwipeable({
    onSwipedRight: drawerActions.toggle
  });

  return (
    <div {...swipeHandlers} className="cp-drawer-toggle hidden-lg">
      <span>{children}</span>
    </div>
  );
};

Toggle.propTypes = {
  drawerActions: PropTypes.shape({
    toggle: PropTypes.func.isRequired
  }).isRequired,
  children: childrenShape
};

export default Toggle;
