import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import * as Icons from '@bibliocommons/base-icons';

import { MENU_ITEM_LINK_TYPE } from 'app/constants/menuConstants';
import menuItemShape from 'app/shapes/menuItem';

import './Menu.scss';

const Menu = ({ menuItems }) => {
  return (
    <nav className="cp-sidebar-menu_menu">
      <ul className="menu">
        {menuItems.map(navItem => {
          const isExpanded = navItem.isExpanded;
          const hasRouterLink = navItem.linkType === MENU_ITEM_LINK_TYPE.HISTORY;
          const hasAnchor = navItem.linkType === MENU_ITEM_LINK_TYPE.STANDARD;
          const hasTextOnly = navItem.linkType === MENU_ITEM_LINK_TYPE.NONE;

          return (
            <li className="menu-item" key={navItem.url}>
              {hasRouterLink && (
                <Link
                  className={cn('menu-label', 'menu-label--interactive', 'menu-label--primary', {
                    'menu-label--active': navItem.isActive
                  })}
                  to={navItem.url}
                >
                  {navItem.label}
                </Link>
              )}
              {hasAnchor && (
                <a
                  className={cn('menu-label', 'menu-label--interactive', 'menu-label--primary', {
                    'menu-label--active': navItem.isActive
                  })}
                  href={navItem.url}
                >
                  {navItem.label}
                </a>
              )}
              {hasTextOnly && <span className={cn('menu-label', 'menu-label--primary')}>{navItem.label}</span>}
              {isExpanded && (
                <ul className="menu menu--secondary">
                  {navItem.children.map(childNavItem => {
                    const Icon = Icons[childNavItem.icon];

                    return (
                      <li className={cn('menu-item', 'menu-item--secondary')} key={childNavItem.url}>
                        <Link
                          className={cn('menu-label', 'menu-label--interactive', 'menu-label--secondary', {
                            'menu-label--active': childNavItem.isActive
                          })}
                          to={childNavItem.url}
                        >
                          {Icon && (
                            <span className="icon">
                              <Icon />
                            </span>
                          )}
                          {childNavItem.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(menuItemShape).isRequired
};

export default Menu;
