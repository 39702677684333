import PropTypes from 'prop-types';

import { LIST_TYPE, LIST_VISIBILITY_TYPE } from 'app/constants/list';

export default PropTypes.shape({
  createdTimestamp: PropTypes.number.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  isRanked: PropTypes.bool,
  listId: PropTypes.string.isRequired,
  relatedMetadataId: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(LIST_TYPE)).isRequired,
  updatedTimestamp: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  visibilityType: PropTypes.oneOf(Object.values(LIST_VISIBILITY_TYPE)).isRequired
});
