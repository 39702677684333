import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { WINDOW_WIDTH_MIN_PX } from 'app/constants/window';

const getWindowWidthPx = window => {
  const widthPx = window?.innerWidth;
  if (Number.isNaN(widthPx)) {
    return WINDOW_WIDTH_MIN_PX.EXTRA_LARGE;
  }

  if (widthPx < WINDOW_WIDTH_MIN_PX.SMALL) {
    return WINDOW_WIDTH_MIN_PX.EXTRA_SMALL;
  }
  if (WINDOW_WIDTH_MIN_PX.SMALL <= widthPx && widthPx < WINDOW_WIDTH_MIN_PX.MEDIUM) {
    return WINDOW_WIDTH_MIN_PX.SMALL;
  }
  if (WINDOW_WIDTH_MIN_PX.MEDIUM <= widthPx && widthPx < WINDOW_WIDTH_MIN_PX.LARGE) {
    return WINDOW_WIDTH_MIN_PX.MEDIUM;
  }
  if (WINDOW_WIDTH_MIN_PX.LARGE <= widthPx && widthPx < WINDOW_WIDTH_MIN_PX.EXTRA_LARGE) {
    return WINDOW_WIDTH_MIN_PX.LARGE;
  }

  return WINDOW_WIDTH_MIN_PX.EXTRA_LARGE;
};

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(() => getWindowWidthPx());

  useEffect(() => {
    let isMounted = true;
    const debouncedResizeHandler = debounce(() => {
      if (!isMounted) {
        return;
      }

      setWindowWidth(getWindowWidthPx(window));
    }, 200);

    window?.addEventListener('resize', debouncedResizeHandler);

    debouncedResizeHandler();

    return () => {
      isMounted = false;
      window?.removeEventListener('resize', debouncedResizeHandler);
    };
  }, [setWindowWidth]);

  return windowWidth;
};

export default useWindowWidth;
