import Immutable from 'immutable';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { childrenShape } from '@bibliocommons/bc-prop-types';
import { TOGGLZ_FEATURES } from '@bibliocommons/constants-feature-togglz';

import ErrorPage from 'app/components/ErrorPage';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import ActiveFilters from 'app/components/shared/Facets/ActiveFilters';
import Facets from 'app/components/shared/Facets';
import MobileMenuOverlay from 'app/components/shared/MobileMenuOverlay';
import { FACET_CATEGORIES, NAV_ITEM_ID, SUB_PAGE_SLUG } from 'app/constants/BrowseConstants';
import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import { FILTER_TYPE } from 'app/constants/FacetsConstants';
import { MENU_ITEM_LINK_TYPE } from 'app/constants/menuConstants';
import {
  mapCatalogSearchFieldsToActiveFilters,
  mapCatalogSearchFieldsToFacetFields,
  serializeFieldIdForQuerystring
} from 'app/helpers/browse';
import { mapSearchBibToDisplayBib } from 'app/helpers/displayBib';
import useFeature from 'app/hooks/useFeature';
import { selectBibEntities } from 'app/selectors/EntitiesSelector';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import BrowseTitlesPage from './BrowseTitlesPage';

const MOBILE_MENU_OVERLAY_KEY = 'browse-titles-mobile-menu-overlay';
const PATH_PREFIX = '/v2/browse/titles';

const BrowseTitlesPageContainer = ({ children }) => {
  const { push } = useHistory();
  const intl = useIntl();
  const { pathname, search } = useLocation();
  const isCoreV2NewAndOnOrderPagesEnabled = useFeature(TOGGLZ_FEATURES.CORE_V2_NEW_AND_ON_ORDER_PAGES);
  const currentLibrary = useSelector(selectCurrentLibrary);
  const searchResults = useSelector(state => state.getIn(['search', 'catalogSearch', 'results']))?.toJS() ?? [];
  const searchBibs = useSelector(selectBibEntities);
  const fields = useSelector(state => state.getIn(['search', 'catalogSearch', 'fields']));
  const isFetching = useSelector(state => state.getIn(['search', 'catalogSearch', 'isFetching'])) ?? false;
  const pagination = useSelector(state => state.getIn(['search', 'catalogSearch', 'pagination']));

  if (!isCoreV2NewAndOnOrderPagesEnabled) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />;
  }

  const displayBibs = searchResults
    .map(({ representative: metadataId }) => searchBibs.get(metadataId)?.toJS() ?? null)
    .map(mapSearchBibToDisplayBib);

  const searchParams = new URLSearchParams(search);

  const tabLinks = [
    {
      id: SUB_PAGE_SLUG.NEW,
      isActive: pathname.endsWith(`/${SUB_PAGE_SLUG.NEW}`),
      text: intl.formatMessage({ defaultMessage: 'New', id: 'browse.nav.new' }),
      to: `${PATH_PREFIX}/${SUB_PAGE_SLUG.NEW}`
    },
    {
      id: SUB_PAGE_SLUG.ON_ORDER,
      isActive: pathname.endsWith(`/${SUB_PAGE_SLUG.ON_ORDER}`),
      text: intl.formatMessage({ defaultMessage: 'On Order', id: 'browse.nav.on_order' }),
      to: `${PATH_PREFIX}/${SUB_PAGE_SLUG.ON_ORDER}`
    }
  ];

  const NAV_ITEM_BY_ID = {
    [NAV_ITEM_ID.AWARDS]: {
      id: NAV_ITEM_ID.AWARDS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Award winners', id: 'browse.nav.awards' }),
      url: '/explore/awards'
    },
    [NAV_ITEM_ID.BEST_SELLERS]: {
      id: NAV_ITEM_ID.BEST_SELLERS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Best sellers', id: 'browse.nav.best_sellers' }),
      url: '/explore/bestsellers'
    },
    [NAV_ITEM_ID.LANGUAGES]: {
      id: NAV_ITEM_ID.LANGUAGES,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'World languages', id: 'browse.nav.languages' }),
      url: '/explore/featured_lists/languages'
    },
    [NAV_ITEM_ID.NEW_AND_ON_ORDER]: {
      id: NAV_ITEM_ID.NEW_AND_ON_ORDER,
      isActive: true,
      text: intl.formatMessage({ defaultMessage: 'New and on order', id: 'browse.nav.new_and_on_order' }),
      url: `${PATH_PREFIX}/${SUB_PAGE_SLUG.NEW}`
    },
    [NAV_ITEM_ID.STAFF_PICKS]: {
      id: NAV_ITEM_ID.STAFF_PICKS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Staff picks', id: 'browse.nav.staff_picks' }),
      url: '/explore/featured_lists/staff_picks'
    },
    [NAV_ITEM_ID.TEACHER_PICKS]: {
      id: NAV_ITEM_ID.TEACHER_PICKS,
      isActive: false,
      text: intl.formatMessage({ defaultMessage: 'Teacher picks', id: 'browse.nav.teacher_picks' }),
      url: '/explore/featured_lists/teacher_picks'
    }
  };
  const navSettings = currentLibrary.getIn(['browse', 'nav'])?.toJS() ?? {};
  const navItems = [
    NAV_ITEM_ID.NEW_AND_ON_ORDER,
    NAV_ITEM_ID.AWARDS,
    NAV_ITEM_ID.BEST_SELLERS,
    NAV_ITEM_ID.STAFF_PICKS,
    NAV_ITEM_ID.LANGUAGES,
    NAV_ITEM_ID.TEACHER_PICKS
  ]
    .filter(navItemId => navItemId === NAV_ITEM_ID.NEW_AND_ON_ORDER || navSettings[`has${navItemId}`])
    .map(navItemId => NAV_ITEM_BY_ID[navItemId]);

  const mobileMenuItems = navItems.map(navItem => {
    const { isActive, text: label, url } = navItem;

    return {
      isActive,
      isExpanded: false,
      label,
      linkType: url.startsWith(PATH_PREFIX) ? MENU_ITEM_LINK_TYPE.HISTORY : MENU_ITEM_LINK_TYPE.STANDARD,
      url
    };
  });

  const mobileMenuOverlayTitle = intl.formatMessage({ defaultMessage: 'Browse', id: 'browse' });
  const pageTitle = intl.formatMessage({ defaultMessage: 'New and on order', id: 'browse.nav.new_and_on_order' });
  const totalResults = pagination.get('count');

  const makeFilterLabel = (fieldId, fieldFilter) => {
    const { value: filterId } = fieldFilter;

    return intl.formatMessage({
      defaultMessage: filterId,
      id: `${fieldId}.${filterId.toLowerCase()}`
    });
  };

  const activeFilters = Immutable.fromJS(mapCatalogSearchFieldsToActiveFilters(fields.toJS(), searchParams));
  const facetFields = Immutable.fromJS(
    mapCatalogSearchFieldsToFacetFields(fields.toJS(), searchParams, makeFilterLabel)
  );

  const handleToggleFilter = (fieldId, currentFilter) => {
    const { value: changedFilterId } = currentFilter?.toJS();

    const serializedFieldId = serializeFieldIdForQuerystring(fieldId);
    const isApplied = searchParams.getAll(serializeFieldIdForQuerystring(serializedFieldId)).includes(changedFilterId);

    if (isApplied) {
      searchParams.delete(serializedFieldId, changedFilterId);
    } else {
      searchParams.append(serializedFieldId, changedFilterId);
    }

    // whenever a filter changes, the pagination will need to reset to page 1
    searchParams.delete('page');

    push(`?${searchParams.toString()}`);
  };

  const renderActiveFilters = () => (
    <ActiveFilters
      activeFilters={activeFilters}
      categories={FACET_CATEGORIES}
      filterType={FILTER_TYPE.BROWSE}
      handleToggleFilter={handleToggleFilter}
      headingTag="h2"
    />
  );

  const renderFacets = () => (
    <Facets
      activeFilters={activeFilters}
      categories={FACET_CATEGORIES}
      facetFields={facetFields}
      filterType={FILTER_TYPE.BROWSE}
      handleToggleFilter={handleToggleFilter}
      totalResults={totalResults}
    />
  );

  const renderMobileMenuOverlay = () => {
    return (
      <MobileMenuOverlay
        menuItems={mobileMenuItems}
        overlayKey={MOBILE_MENU_OVERLAY_KEY}
        title={mobileMenuOverlayTitle}
        triggerLabel={pageTitle}
      />
    );
  };

  return (
    <>
      <DocumentHeadContainer pageTitle={pageTitle} />
      <BrowseTitlesPage
        displayBibs={displayBibs}
        isFetching={isFetching}
        navItems={navItems}
        pageTitle={pageTitle}
        pagination={pagination}
        renderActiveFilters={renderActiveFilters}
        renderFacets={renderFacets}
        renderMobileMenuOverlay={renderMobileMenuOverlay}
        tabLinks={tabLinks}
      >
        {children}
      </BrowseTitlesPage>
    </>
  );
};

BrowseTitlesPageContainer.propTypes = {
  children: childrenShape.isRequired
};

export default BrowseTitlesPageContainer;
